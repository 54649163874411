import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  urlInfoContaEmpresas,
  urlAssinarPlano,
  tokenUsuario,
  apiUrl,
} from "../../../utils/constants";
import MenuSide from "../../../components/empresas/MenuSide";
import {
  IonCard,
  IonItem,
  IonLabel,
  IonContent,
  IonBadge,
  IonList,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonButton,
  IonPage,
} from "@ionic/react";

import HeaderApp from "../../../components/global/HeaderItem";

import { useNavigate } from "react-router-dom";

import {
  showActionSheet,
  toastNotificacao,
  CopiarTexto,
} from "../../../utils/functions";

import moment from "moment";

const consultaConta = async () => {
  try {
    const response = await axios.post(urlInfoContaEmpresas, {
      token: tokenUsuario,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const logout = () => {
  document.cookie =
    "token_usuario_agendou" +
    "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.removeItem('tipoUsuario');
  window.location.href = "/";
};

const modalLogout = () => {
  showActionSheet("Deseja realmente sair da conta atual?", [
    {
      text: "Sim",
      handler: () => {
        logout();
      },
    },
    {
      text: "Cancelar",
      handler: () => {
        return;
      },
    },
  ]);
};

export default function InicioUsuario() {
  const [nomeUsuario, setNomeUsuario] = useState("");
  const [infoUsuario, setInfoUsuario] = useState([]);
  const [modalPlano, setModalPlano] = useState(false);
  const navigate = useNavigate();

  const carregarSessao = async () => {
    try {
      const response = await axios.post(apiUrl + "empresas/sessao/", {
        token: tokenUsuario,
      });
      if (response.data === 1) {
        return null;
      } else {
        navigate({ pathname: "/empresas/login" });
      }
    } catch (error) {
      return null;
    }
  };

  const assinarPLano = async () => {
    try {
      const response = await axios.post(urlAssinarPlano, {
        token: tokenUsuario,
      });
      let status = response.data.status;
      let link = response.data.url;
      if (status === "sucesso") {
        const alert = document.createElement("ion-alert");
        alert.header = "Efetue o pagamento";
        alert.message =
          "Assim que o pagamento for confirmado, seu plano será ativado em alguns minutos!";
        alert.mode = "ios";
        alert.buttons = [
          {
            text: "Pagar",
            role: "confirm",
            handler: () => {
              window.location.href = link;
            },
          },
        ];
        document.body.appendChild(alert);
        await alert.present();
      } else {
        await toastNotificacao(
          "danger",
          "alert-outline",
          "Ocorreu um erro inesperado, caso persista entre em contato com o suporte!",
          3000,
          "Ok"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const run = async () => {
      const infoUsuario = await consultaConta();
      setNomeUsuario(infoUsuario.nome);
      setInfoUsuario(infoUsuario);
    };
    run();
    carregarSessao();
  }, []);

  const CopiarLink = async (usuario) => {
    let url = "https://agendou.gerenciadores.com.br/empresa?empresa=";
    let mensagem =
      "Link copiado com sucesso, compartilhe o link com os seus clientes.";
    let tempo = 5000;
    let textoCopiar = url + usuario;
    await CopiarTexto(mensagem, tempo, textoCopiar);
  };

  const AgendouWeb = async () => {
    let url = "https://agendou.gerenciadores.com.br/";
    let mensagem =
      "Link copiado com sucesso, acesse o Agendou Web no navegador no celular, tablet ou PC";
    let tempo = 5000;
    let textoCopiar = url;
    await CopiarTexto(mensagem, tempo, textoCopiar);
  };

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <div className="ion-margin">
          <div className="ion-text-center" style={{ paddingTop: "5px" }}>
            <h2 style={{ fontWeight: 700, marginTop: 10, marginBottom: 10 }}>
              {nomeUsuario}
            </h2>
            {infoUsuario.plano === "Grátis" ? (
              <div>
                <IonBadge color="secondary-light" style={{ fontSize: 10 }}>
                  <i className="ri-leaf-line"></i> Grátis
                </IonBadge>
              </div>
            ) : (
              <div>
                <IonBadge color="primary" style={{ fontSize: 10 }}>
                  <i className="ri-vip-crown-line"></i> Profissional
                </IonBadge>
              </div>
            )}
          </div>
        </div>
        <IonList>
          <IonCard
            color="light"
            onClick={() => {
              CopiarLink(infoUsuario.nome_usuario);
            }}
            button={true}
          >
            <IonLabel>
              <div className="ion-margin">
                <i className="ri-global-line"></i>&nbsp; Copiar link da sua
                Empresa
              </div>
            </IonLabel>
          </IonCard>

          <IonCard
            color="secondary-light"
            onClick={() => {
              AgendouWeb();
            }}
            button={true}
          >
            <IonLabel style={{ color: "#ffffff" }}>
              <div className="ion-margin" style={{ lineHeight: "20px" }}>
                <i className="ri-global-line"></i>&nbsp; Acesse o Agendou Web
                em: agendou.gerenciadores.com.br
              </div>
            </IonLabel>
          </IonCard>

          {infoUsuario.plano === "Grátis" ? (
            <IonCard
              style={{ background: "#3D2DF9", fontWeight: 900 }}
              onClick={() => {
                setModalPlano(true);
              }}
              button={true}
            >
              <IonLabel>
                <div className="ion-margin" style={{ color: "#ffffff" }}>
                  <i className="ri-star-line"></i> &nbsp; Assinar plano
                  profissional por R$ 7,90 mensal
                </div>
              </IonLabel>
            </IonCard>
          ) : (
            <IonCard color="success">
              <IonLabel>
                <div
                  className="ion-margin-horizontal"
                  style={{ color: "#ffffff", marginTop: 10, marginBottom: 10 }}
                >
                  <i className="ri-star-line"></i> &nbsp;Data expiração do
                  plano:{" "}
                  {moment(infoUsuario.expiracao_plano).format("DD/MM/YYYY")} ás{" "}
                  {moment(infoUsuario.expiracao_plano).format("HH:mm")}
                </div>
              </IonLabel>
            </IonCard>
          )}

          <IonCard
            color="warning"
            onClick={() => {
              navigate({ pathname: "/empresas/suporte" });
            }}
            button={true}
          >
            <IonLabel style={{ color: "#ffffff" }}>
              <div className="ion-margin" style={{ lineHeight: "20px" }}>
                <i className="ri-question-line"></i>&nbsp; Precisa de ajuda?
                Entre em contato conosco clicando aqui!
              </div>
            </IonLabel>
          </IonCard>

          {infoUsuario.plano === "Grátis" ? (
            <IonItem
              onClick={() => {
                navigate({ pathname: "/empresas/consumo" });
              }}
              button={true}
            >
              <IonLabel>
                <i className="ri-bar-chart-line"></i> &nbsp;Consumo dos recursos
                gratuitos mensais
              </IonLabel>
            </IonItem>
          ) : (
            <></>
          )}
          <IonItem
            onClick={() => {
              navigate({ pathname: "/empresas/sugestoes" });
            }}
            button={true}
          >
            <IonLabel>
              <i className="ri-alarm-warning-line"></i> &nbsp;Sugestões e melhorias
            </IonLabel>
          </IonItem>
          <IonItem
            onClick={() => {
              navigate({ pathname: "/empresas/dashboard" });
            }}
            button={true}
          >
            <IonLabel>
              <i className="ri-pie-chart-line"></i> &nbsp;Dashboard
            </IonLabel>
          </IonItem>
          <IonItem
            onClick={() => {
              navigate({ pathname: "/empresas/calendario" });
            }}
            button={true}
          >
            <IonLabel>
              <i className="ri-calendar-check-line"></i> &nbsp;Calendário/Agenda
            </IonLabel>
          </IonItem>
          <IonItem
            onClick={() => {
              navigate({ pathname: "/empresas/agendar" });
            }}
            button={true}
          >
            <IonLabel>
              <i className="ri-add-line"></i> &nbsp; Agendar horário
            </IonLabel>
          </IonItem>
          <IonItem
            onClick={() => {
              navigate({ pathname: "/empresas/agendamentos-dia" });
            }}
            button={true}
          >
            <IonLabel>
              <i className="ri-calendar-event-fill"></i> &nbsp; Agendamentos do
              dia
            </IonLabel>
          </IonItem>
          <IonItem
            onClick={() => {
              navigate({ pathname: "/empresas/calendario-semana" });
            }}
            button={true}
          >
            <IonLabel>
              <i className="ri-calendar-todo-line"></i> &nbsp; Agendamentos da
              semana
            </IonLabel>
          </IonItem>
          <IonItem
            onClick={() => {
              navigate({ pathname: "/empresas/dias_nao_atendidos" });
            }}
            button={true}
          >
            <IonLabel>
              <i className="ri-calendar-close-fill"></i> &nbsp; Dias não
              atendidos
            </IonLabel>
          </IonItem>

          <IonItem
            onClick={() => {
              navigate({ pathname: "/empresas/dias_excessao" });
            }}
            button={true}
          >
            <IonLabel>
              <i className="ri-calendar-close-fill"></i> &nbsp; Datas de excessão
            </IonLabel>
          </IonItem>
          <IonItem
            onClick={() => {
              navigate({
                pathname: "/empresas/configuracoes_horario_atendimento",
              });
            }}
            button={true}
          >
            <IonLabel>
              <i className="ri-calendar-schedule-line"></i> &nbsp; Horário de
              atendimento
            </IonLabel>
          </IonItem>
          <IonItem
            onClick={() => {
              navigate({ pathname: "/empresas/servicos" });
            }}
            button={true}
          >
            <IonLabel>
              <i className="ri-store-line"></i> &nbsp; Serviços
            </IonLabel>
          </IonItem>
          <IonItem
            onClick={() => {
              navigate({ pathname: "/empresas/clientes" });
            }}
            button={true}
          >
            <IonLabel>
              <i className="ri-user-line"></i> &nbsp; Clientes
            </IonLabel>
          </IonItem>
          <IonItem onClick={() => modalLogout()} button={true}>
            <IonLabel>
              <i className="ri-logout-box-line"></i> &nbsp; Desconectar
            </IonLabel>
          </IonItem>
        </IonList>
        {modalPlano ? (
          <div>
            <IonModal isOpen={true} mode="ios" backdropDismiss="false">
              <IonHeader>
                <IonToolbar>
                  <IonButtons slot="start">
                    <IonButton
                      onclick={() => {
                        setModalPlano(false);
                      }}
                    >
                      Fechar
                    </IonButton>
                  </IonButtons>
                  <IonTitle>Seja profissional</IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent className="ion-padding">
                <IonLabel className="ion-text-center">
                  <h1 style={{ fontWeight: 700 }}>Recursos</h1>
                </IonLabel>

                <IonList>
                  <IonItem>
                    <IonLabel>
                      <h6>Agendamentos ilimitados</h6>
                    </IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>
                      <h6>Clientes ilimitados</h6>
                    </IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>
                      <h6>Lembrentes via Whatsapp/E-mail ilimitados</h6>
                    </IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>
                      <h6>
                        Receba pagamentos direto <br /> no agendamento
                      </h6>
                    </IonLabel>
                  </IonItem>
                </IonList>

                <br />

                <IonButton
                  color="secondary-light"
                  expand="full"
                  onClick={() => assinarPLano()}
                >
                  Assinar plano &nbsp;{" "}
                  <i className="ri-star-fill icone19px"></i>
                </IonButton>
              </IonContent>
            </IonModal>
          </div>
        ) : null}
        <MenuSide />
      </IonContent>
    </IonPage>
  );
}
