import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { urlAdicionar, tokenUsuario } from "../../../utils/constants";
import MenuSide from "../../../components/empresas/MenuSide";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonPage,
  IonContent,
  IonInput,
} from "@ionic/react";
import HeaderApp from "../../../components/global/HeaderItem";

export default function AdicionarDiasExcessaoEmpresas() {
  const navigate = useNavigate();

  const [dia, setDia] = useState("");
  const [horario1, setHorario1] = useState('');
  const [horario2, setHorario2] = useState('');
  const [horario3, setHorario3] = useState('');
  const [horario4, setHorario4] = useState('');

  const cadastrar = async () => {
    try {
      const response = await axios.post(urlAdicionar("empresas", "dias_excessao"), {
        token: tokenUsuario,
        dia: dia,
        horario_inicial_1: horario1,
        horario_final_1: horario2,
        horario_inicial_2: horario3,
        horario_final_2: horario4
      });
      if (response) {
        navigate({ pathname: "/empresas/dias_excessao" });
      }
    } catch (error) {
      return null;
    }
  };

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <IonItem>
          <IonLabel position="stacked">Dia: </IonLabel>
          <IonInput
            type="date"
            placeholder="Dia"
            onIonInput={(event) => {
              event.preventDefault();
              setDia(event.target.value);
            }}
            value={dia}
          />
        </IonItem>

        <div className="ion-margin">
          <div className="row">
            <div className="col-12">
              <br />
              <IonLabel className="ion-padding" style={{ fontSize: "12px" }}>
                Horários referentes ao primeiro período do dia.
              </IonLabel>
              <br />
            </div>
            <div className="col-6">
              <IonItem>
                <IonLabel position="stacked">De: </IonLabel>
                <IonInput
                  type="time"
                  onIonChange={(e) => setHorario1(e.detail.value)}
                  value={horario1}
                />
              </IonItem>
            </div>
            <div className="col-6">
              <IonItem>
                <IonLabel position="stacked">Até: </IonLabel>
                <IonInput
                  type="time"
                  onIonChange={(e) => setHorario2(e.detail.value)}
                  value={horario2}
                />
              </IonItem>
            </div>
            <div className="col-12">
              <br />
              <IonLabel className="ion-padding" style={{ fontSize: "12px" }}>
                Horários referentes ao segundo período do dia.
              </IonLabel>
              <br />
            </div>
            <div className="col-6">
              <IonItem>
                <IonLabel position="stacked">De: </IonLabel>
                <IonInput
                  type="time"
                  onIonChange={(e) => setHorario3(e.detail.value)}
                  value={horario3}
                />
              </IonItem>
            </div>
            <div className="col-6">
              <IonItem>
                <IonLabel position="stacked">Até: </IonLabel>
                <IonInput
                  type="time"
                  onIonChange={(e) => setHorario4(e.detail.value)}
                  value={horario4}
                />
              </IonItem>
            </div>
          </div>
        </div>

        <div className="ion-margin">
          <IonButton
            color="secondary-light"
            expand="block"
            onClick={() => cadastrar()}
          >
            Confirmar
          </IonButton>
        </div>
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
