/* eslint-disable */
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  urlHorariosAtendimentoUsuario,
  urlHorariosDisponiveisAgendamentoUsuario,
  urlServicosAgendamentoUsuario,
  urlAdicionar,
  tokenUsuario,
} from "../../../utils/constants";
import MenuSide from "../../../components/usuarios/MenuSide";
import {
  IonCard,
  IonItem,
  IonLabel,
  IonContent,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonTextarea,
} from "@ionic/react";
import moment from "moment";
import './style.css';
import { useNavigate } from "react-router-dom";
import Calendar from 'rsuite/Calendar';
import ptBR from 'rsuite/locales/pt_BR';
import 'rsuite/Calendar/styles/index.css';
import HeaderApp from "../../../components/global/HeaderItem";

import { toastNotificacao } from "../../../utils/functions";
const consultaHorariosAtendimento = async (dataAgendamento) => {
  try {
    const response = await axios.post(urlHorariosAtendimentoUsuario, {
      token: tokenUsuario,
      data_agendamento: dataAgendamento,
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const consultaHorariosDisponiveis = async (dataAgendamento) => {
  try {
    const response = await axios.post(
      urlHorariosDisponiveisAgendamentoUsuario,
      {
        token: tokenUsuario,
        data_selecionada: dataAgendamento,
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

const consultaServicos = async () => {
  try {
    const response = await axios.post(urlServicosAgendamentoUsuario, {
      token: tokenUsuario,
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const getDayOfWeek = (dateString) => {
  const days = [
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado",
    "Domingo",
  ];
  const date = new Date(dateString);
  const dayIndex = date.getDay();
  return days[dayIndex];
};

const getDatesOfMonth = (year, month) => {
  const startDate = new Date(year, month - 1, 1);
  const endDate = new Date(year, month, 0);
  const datesArray = [];

  for (
    let date = startDate;
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    const currentDate = new Date(date);
    if (currentDate.getMonth() === month - 1) {
      // Verifica se o mês corresponde ao mês especificado
      const data = currentDate.toISOString().split("T")[0]; // Formato AAAA-MM-DD
      const dia = currentDate.getDate();
      const mes = currentDate.getMonth() + 1; // Lembrando que os meses são baseados em zero
      const ano = currentDate.getFullYear();

      datesArray.push({ data, dia, mes, ano });
    }
  }

  return datesArray;
};

function groupDatesByWeekday(dates) {
  const weekdays = ["DOM.", "SEG.", "TER.", "QUA.", "QUI.", "SEX.", "SÁB."];
  const groupedDates = {
    "DOM.": [],
    "SEG.": [],
    "TER.": [],
    "QUA.": [],
    "QUI.": [],
    "SEX.": [],
    "SÁB.": [],
  };

  dates.forEach((date) => {
    const weekdayIndex = new Date(date.data).getDay();
    const weekday = weekdays[weekdayIndex];
    groupedDates[weekday].push(date);
  });

  return groupedDates;
}

export default function AgendarUsuario() {
  const dataAtual = moment().format("YYYY-MM-DD");

  const [dataAgendamento, setDataAgendamento] = useState(dataAtual);
  const [dataAgendamentoExibicao, setDataAgendamentoExibicao] = useState(new Date());
  const [horaAgendamento, setHoraAgendamento] = useState("");
  const [obsAgendamento, setObsAgendamento] = useState("");
  const [servicoAgendamento, setServicoAgendamento] = useState("");

  const [corMensagemDiaSelecionado, setCorMensagemDiaSelecionado] =
    useState("secondary-light");
  const [mensagemDiaSelecionado, setMensagemDiaSelecionado] = useState("");
  const [horariosDisponiveis, setHorariosDisponiveis] = useState([]);
  const [servicos, setServicos] = useState([]);
  const navigate = useNavigate();

  const [mesAtual, setMesAtual] = useState(moment().format("MM"));
  const [anoAtual, setAnoAtual] = useState(moment().format("YYYY"));
  const [dias, setDias] = useState(getDatesOfMonth(anoAtual, mesAtual));
  const [diasSemana, setDiasSemana] = useState(groupDatesByWeekday(dias));


  useEffect(() => {
    setMesAtual(moment().format("MM"));
    setAnoAtual(moment().format("YYYY"));
    setDias(getDatesOfMonth(anoAtual, mesAtual));
    setDiasSemana(groupDatesByWeekday(dias));
  }, []);

  const agendarHorario = () => {
    axios
      .post(urlAdicionar("usuarios", "agendar"), {
        data_agendamento: moment(dataAgendamento).format("YYYY-MM-DD"),
        hora_agendamento: horaAgendamento,
        servico_id: Number(servicoAgendamento),
        obs: obsAgendamento,
        token: tokenUsuario,
      })
      .then(async (response) => {
        if (response.data.status == "agendamento_efetuado") {
          const irParaAgendmamentos = () => {
            navigate({ pathname: "/usuarios/agendamentos" });
          };
          await toastNotificacao(
            "success",
            "alert-outline",
            "Agendamento efetuado com sucesso.",
            2000,
            "Ok",
            irParaAgendmamentos()
          );
        } else if (response.data.status == "horario_indisponivel") {
          await toastNotificacao(
            "danger",
            "alert-outline",
            "Ops, alguém acabou de agendar nesse horário.",
            60000,
            "Ok"
          );
        } else if (response.data.status == "campo_vazio") {
          await toastNotificacao(
            "warning",
            "alert-outline",
            "Ops, o campo " + response.data.campo + " precisa ser preenchido!",
            60000,
            "Ok"
          );
        } else if (response.data.status == "nao_logado") {
          const alert = document.createElement("ion-alert");
          alert.header = "Atenção";
          alert.mode = "ios";
          alert.message =
            "Você precisa estar logado na sua conta para fazer um agendamento!";
          alert.buttons = [
            {
              text: "Entrar",
              role: "confirm",
              handler: () => {
                navigate({ pathname: "/entrar" });
              },
            },
          ];
          document.body.appendChild(alert);
          await alert.present();
        } else if (response.data.status == "empresa_nao_selecionada") {
          await toastNotificacao(
            "danger",
            "alert-outline",
            "Nenhuma empresa selecionada.",
            60000,
            "Ok"
          );
        } else if (response.data.status == "nao_atendemos_nesse_dia") {
          await toastNotificacao(
            "danger",
            "alert-outline",
            "Ops, nś não atendemos no dia selecionado.",
            60000,
            "Ok"
          );
        } else if (response.data.status == "fora_horario") {
          await toastNotificacao(
            "danger",
            "alert-outline",
            "Ops, nós não atendemos no horário selecionado.",
            60000,
            "Ok"
          );
        } else if (response.data.status == "limite_atingido") {
          const alert = document.createElement("ion-alert");
          alert.header = "Atenção";
          alert.mode = "ios";
          alert.message =
            "Seu limite de agendamentos mensais do plano grátis foi atingido, contrate o plano profissional para utilizar sem limitações!";
          alert.buttons = [
            {
              text: "Fechar",
              role: "cancel",
              handler: () => {
                console.log("cancelado");
              },
            },
            {
              text: "Contratar",
              role: "confirm",
              handler: () => {
                navigate({ pathname: "/inicio" });
              },
            },
          ];
          document.body.appendChild(alert);
          await alert.present();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const verificarServicos = async () => {
      const resultado = await consultaServicos();
      setServicos(resultado);
    };
    verificarServicos();
    console.log(diasSemana);
  }, [diasSemana]);

  useEffect(() => {
    const verificarHorarios = async () => {
      const resultadoHorario = await consultaHorariosAtendimento(
        dataAgendamento
      );
      setMensagemDiaSelecionado(resultadoHorario.mensagem);
      if (resultadoHorario.status === "disponivel") {
        setCorMensagemDiaSelecionado("success");
      } else {
        setCorMensagemDiaSelecionado("danger");
      }
    };
    verificarHorarios();

    const verificarHorariosDisponiveis = async () => {
      const resultadoHorario = await consultaHorariosDisponiveis(
        dataAgendamento
      );
      setHorariosDisponiveis(resultadoHorario);
    };
    verificarHorariosDisponiveis();
  }, [dataAgendamento]);

  const dateAtual = new Date();
  const handleDateChange = (newDate) => {
    setDataAgendamentoExibicao(newDate);
    const formattedDate = moment(newDate).format("YYYY-MM-DD");
    setDataAgendamento(formattedDate);
  };
  return (
    <IonPage>
      <IonContent>
        <HeaderApp />
        <div className="ion-margin">
          <IonLabel position="stacked">Escolha uma data</IonLabel>

          <Calendar
           onChange={handleDateChange}
            locale={ptBR}
            compact={true}
            value={dataAgendamentoExibicao}
          />

          <IonCard color={corMensagemDiaSelecionado}>
            <div style={{ margin: 12 }}>
              {moment(dataAgendamento).format('DD/MM/YYYY')} - {getDayOfWeek(dataAgendamento)} : {mensagemDiaSelecionado}
            </div>
          </IonCard>
          <IonLabel position="stacked">Escolha um serviço</IonLabel>
          <IonSelect
            mode="ios"
            aria-label="Serviço"
            placeholder="Clique aqui"
            onIonChange={(e) => setServicoAgendamento(e.detail.value)}
          >
            {servicos.map((servico, index) => (
              <IonSelectOption key={index} value={servico.id}>
                {servico.nome}
              </IonSelectOption>
            ))}
          </IonSelect>

          <div className="row">&nbsp;</div>
          {servicoAgendamento !== "" ? (
            <div>
              <div>
                <ion-label position="stacked">Defina o horário</ion-label>
                <div className="row">&nbsp;</div>
              </div>
              <div className="ion-margin-horizontal">
                <div className="row">
                  {horariosDisponiveis.map((horario, index) => (
                    <div className="col-sm-3 col-6" key={index}>
                      <input
                        id={`horarioId${horario}`}
                        className="checa radio_item hora_agendamento"
                        type="radio"
                        value={horario}
                        name="grupoHorarios"
                        onChange={(event) => {
                          setHoraAgendamento(event.target.value);
                          console.log(horaAgendamento);
                        }}
                      />
                      <label
                        className="label_item"
                        htmlFor={`horarioId${horario}`}
                      >
                        <div>
                          <i className="ri-timer-line"></i>&nbsp;{" "}
                          <span>{horario}</span>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <span></span>
          )}

          <IonLabel position="stacked">Alguma observação?</IonLabel>
          <IonItem>
            <IonTextarea
              className="obs"
              placeholder="Informe sua observação"
              onIonChange={(e) => setObsAgendamento(e.detail.value)}
              value={obsAgendamento}
            ></IonTextarea>
          </IonItem>
          <br />
          <IonButton
            expand="full"
            color="secondary-light"
            onClick={agendarHorario}
          >
            Agendar &nbsp; <i className="ri-calendar-line icone19px"></i>
          </IonButton>
          <br />
        </div>
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
